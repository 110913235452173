import styled from "styled-components"

export const ContactWrapper = styled.div`
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  gap: 30px;
  background: #0022B7;
  border-radius: 6px;
  @media (max-width: 992px) {
    margin-top: 56px;
  }
  @media (max-width: 768px) {
    margin-top: 48px;
    flex-direction: column;
    text-align: center;
  }
`

export const Content = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #FFFFFF;
`
