import styled from "styled-components"

export const SearchWrapper = styled.div`
  margin-bottom: 40px;
  transition: 
    top 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s, 
    position 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s, 
    padding 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  z-index: 2;
  @media (max-width: 992px) {
    position: sticky;
    top: 99px;
    padding-bottom: 16px;
  }
`

export const Wrap = styled.div`
  display: grid;
  grid-template: 1fr / 206px 1fr;
  gap: 16px 24px;
  @media (max-width: 992px) {
    max-width: 770px;
    margin: 0px auto;
    padding: 0 15px;
  }
  @media (max-width: 576px) {
    max-width: 506px;
  }
`

export const ButtonWrap = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  button {
    height: unset;
  }
  @media (max-width: 576px) {
    grid-area: 2 / 1 / 3 / 3;
    button {
      width: 100%;
    }
  }
`
export const InputWrap = styled.div`
  position: relative;
  grid-area: 1 / 1 / 3 / 3;
  width: 100%;
  height: 100%;
  min-height: 45px;
  @media (max-width: 992px) {
    grid-area: 1 / 2 / 2 / 3;
  }
  @media (max-width: 576px) {
    grid-area: 1 / 1 / 2 / 3;
  }
  svg {
    position: absolute;
    left: 17px;
    top: 12px;
  }
`

export const Search = styled.input`
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 30px;
  width: 100%;
  height: 100%;
  padding: 10px 14px 10px 48px;
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7A8A9D;
  }
`
