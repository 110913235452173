export const formatIntegration = (integration) => {
  let categories = []
  let departments = []
  let collections = []
  
  integration?.node?.category?.map((cat) => 
    categories.push(cat.title)
  )
  if (integration?.node?.departments) {
    integration?.node?.departments?.map((dep) =>
    departments.push(dep.title)
  )}
  if (integration?.node?.collections) {integration?.node?.collections?.map((col) => 
    collections.push(col.title)
  )}

  return ({
    categories: categories,
    collections: collections,
    ctaInternalLink: integration?.node?.ctaInternalLink,
    ctaLink: integration?.node?.ctaLink,
    departments: departments,
    description: integration?.node?.description,
    heading: integration?.node?.heading,
    headingSize: integration?.node?.headingSize,
    icon: integration?.node?.icon,
    internalName: integration?.node?.internalName,
    subheading: integration?.node?.subheading,
    __typename: integration?.node?.__typename,
    id: integration?.node?.id,
    updated: integration?.node?.updatedAt,
  })
}