import React from "react"

export const InlineLink = ({ category, to }) => {
  // Inline link text updates based on resource category
  let linkText

  if (category === "video" || category === "webinar") {
    linkText = "Watch now"
  } else if (category === "podcast") {
    linkText = "Listen now"
  } else if (category === "guide") {
    linkText = "Download now"
  } else {
    linkText = "Read more"
  }

  if (!to) {
    return <p className="text-link">{linkText}</p>
  } else {
    return (
      <a className="text-link" href={to}>
        {linkText}
      </a>
    )
  }
}
