import React from 'react';
import { Link } from "gatsby";
import { theme } from '@theme';
import cardPlaceholderImg from './card-placeholder.jpg';
import { CardContainer, ImageWrapper, TextWrapper, NewTab, Heading } from './styles/IntegrationsCard.styled';
import { InlineLink } from '../Resources/ResourceCardInlineLink';
import OptimizedImage from '../../utils/optimizedImage';
import { ConditionalWrapper } from '../../atoms/ConditionalWrapper';

export const IntegrationsCard = ({ title, categories, description, author, link, featuredImg, gatsbyImage, border, blogBody, className, alt, type }) => {
  let category
  let newIntegration = false
  if (categories) {
    category = categories[0]
    if (categories.includes("New")) {
      newIntegration = true
    }
  }

  return (
      <ConditionalWrapper
        condition={link}
        wrapper={children => <Link tabIndex={0} style={{ display: 'block', padding: '0px', textDecoration: 'none' }} to={link}>{children}</Link>}
      >
        {className === "popular" && newIntegration === true && 
          <NewTab>
            New
          </NewTab>
        }
        <CardContainer
          data-comp={IntegrationsCard.displayName}
          theme={theme}
          border={border}
          className={`${className} ${newIntegration}`}
        >
          <ImageWrapper>
            <OptimizedImage
              image={gatsbyImage} 
              src={featuredImg || cardPlaceholderImg} 
              alt={alt || title}
            />
          </ImageWrapper>
          <TextWrapper >
            {title && 
              <Heading 
                className="line-clamp-2"
              >
                {title}
              </Heading>
            }
            <div 
              style={{ 
                marginTop: 'auto', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'baseline' 
              }}
            >
              {categories && 
                <InlineLink category={category} />
              }
            </div>
          </TextWrapper>
        </CardContainer>
      </ConditionalWrapper>
  );
};

IntegrationsCard.displayName = 'IntegrationsCard';