import React from "react"
import { ContactWrapper, Content } from "../styles/ContactUsBar.styled"
import { Button } from "../../../atoms/Button"

const ContactUsBar = () => (
  <ContactWrapper>
    <Content>
      Don't see what you're looking for?
    </Content>
    <Button ctaVariant='secondary' ctaLink="/about/contact">Contact Us</Button>
  </ContactWrapper>
)

export default ContactUsBar
