import styled from "styled-components";
import { color } from "../../../atoms/colors";

export const CardContainer = styled.div`
  * {
    margin: 0;
    padding: 0;
  }
  ${({ theme }) =>
  `
    font-family: ${theme.font.primary};
  `}
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #FFFFFF;
  text-align: center;
  ${({ border }) =>
    border &&
  `
    border: 1px solid ${border};
  `}
  border-radius: 9px;
  height: 100%;
  transition: box-shadow 300ms ease;
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  &:focus {
    background: #E4E9F1;
    border: 1px solid #E4E7EC;
  }
  .text-link {
    color: #0022B7;
    font-weight: bold !important;
  }

  .card--read-time {
    font-size: 12px;
    color: #222222;
  }
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .description {
    color: #101828;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &.popular {
    &.true {
      border: 2px solid ${color.common.emphasisPink};
      border-radius: 0px 9px 9px;
    }
  }
`

export const NewTab = styled.div`
  position: absolute;
  margin-top: -25px;
  padding: 4px 10px;
  color: white;
  background: ${color.common.emphasisPink};
  border-radius: 6px 6px 0 0;
`

export const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const Heading = styled.h6`
  margin-bottom: 8px;
  overflow-wrap: anywhere;
  white-space: normal;
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #101828;
`

export const ImageWrapper = styled.div`
  width: 100%;
  object-fit: contain;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
    margin: auto 0;
    img {
      margin: 0 auto;
      width: 100%;
      max-width: 100%;
      max-height: 140px;
      border-radius: 6px;
    }
  }
`