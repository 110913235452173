import React from "react"
import DisplayCount from "./DisplayCount"
import {
  HeadingWrapper,
  SectionHeading,
  CardDeckGrid
} from "../styles/IntegrationsCardDeck.styled"
import {
  NoPosts,
  Sorry,
  DifferentSearch,
  ViewAll,
} from "../styles/AppsListing.styled"
import ComponentPagination from "../../Pagination/Pagination"

const AppsListing = ({ posts, maxPosts, paginatedPosts, currentPage, setCurrentPage, searching, viewAll, handleViewAll, scrollToTop, isTablet }) => {

  let buttonText = viewAll ? "View Popular Apps" : "View All";

  const handleClick = (num) => {
    setCurrentPage(num)
    isTablet ? scrollToTop(450) : scrollToTop()
  }

  return (
    <>
      {!searching && posts.length !== 0 &&
        <HeadingWrapper>
          <SectionHeading>
            All App Integrations
          </SectionHeading>
          {currentPage === 1 && <ViewAll onClick={() => handleViewAll()}>{buttonText}</ViewAll>}
        </HeadingWrapper>
      }
      {searching && posts.length !== 0 &&
        <DisplayCount page={currentPage} posts={posts} postsPerPage={maxPosts} />
      }
      {paginatedPosts.length !== 0 &&
        <CardDeckGrid>
          {paginatedPosts}
        </CardDeckGrid>
      }
      {posts.length === 0 &&
        <NoPosts>
          <Sorry>Sorry, no results found.</Sorry>
          <DifferentSearch>Try a different search.</DifferentSearch>
        </NoPosts>
      }
      <ComponentPagination
        current={currentPage}
        total={posts.length}
        pageSize={maxPosts}
        func={handleClick}
        marginTop={48}
      />
    </>
  )
}

export default AppsListing
