import styled from "styled-components"

export const ListingWrapper = styled.div`
  background: #F0F0F0;
  font-family: 'Roboto';
  font-style: normal;
`

export const CardDeckWrapper = styled.div`
  margin: 0 auto;
  padding: 96px 15px;
  max-width: 1170px;
  display: grid;
  grid-template-columns: 25fr 67fr;
  grid-column-gap: 8%;
  grid-row-gap: 48px;
  @media (max-width: 992px) {
    max-width: 770px;
    padding: 0 15px 56px;
    grid-template-columns: 1fr;
  }
  @media (max-width: 576px) {
    max-width: 506px;
    padding: 0 15px 48px;
  }
`

export const ContentWrapper = styled.div`
`

export const CardDeckGrid = styled.div`
  margin: 0 auto;
  display: grid;
  width: 100%;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 375px) {
    grid-template-columns: 1fr;
  }
`

export const SectionHeading = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #101828;
  display: inline-block;
  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 34px;
  }
`

export const PopularAppsSection = styled.div`
  margin-bottom: 56px;
`

export const PopularGrid = styled.div`
  margin: 40px auto 0;
  display: grid;
  width: 100%;
  grid-column-gap: 25px;
  grid-row-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 375px) {
    grid-template-columns: 1fr;
  }
`

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`
