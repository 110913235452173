import styled from "styled-components";

export const IntegrationHeroWrap = styled.div`
  margin-top: 100px;
  font-family: Roboto, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  .background-image {
    position: absolute;
    min-width: 100vw;
    height: 100%;
    z-index: 0;
  }
`

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1170px;
  padding: 52px 0 96px;
  width: 100%;
  z-index: 1;
  position: relative;
  align-items: center;
  @media (max-width: 992px) {
    max-width: 770px;
    padding: 52px 0 64px;
    .breadcrumb-wrap {
      position: absolute;
      top: 24px;
    }
  }
  @media (max-width: 576px) {
    max-width: 506px;
    padding: 52px 0 48px;
  }
`

export const Heading = styled.h1`
  padding-bottom: 24px;
  color: white;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 60px;
  @media (max-width: 992px) {
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
  }
`

export const Subheading = styled.p`
  margin-bottom: 0;
  color: white;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 15px;
  max-width: 770px;
  margin: 0 auto;
  height: 100%;
`

export const BtnWrapper = styled.div`
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
  button {
    margin-right: 15px;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 15px;
    }
  }
`

export const BreadcrumbContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
  align-items: center;
  padding: 25px 15px 0 15px;
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 576px) {
    max-width: 506px;
  }
`
