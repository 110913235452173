import styled, { css } from "styled-components"

export const CardDeckSideBar = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`

export const ClearButton = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  gap: 18px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #002DC2;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

export const SidebarCTA = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #101828;
  a {
    display: block;
    margin-top: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #002DC2;
    text-decoration: none;
    svg {
      margin-left: 8px;
    }
    :hover {
      text-decoration-line: underline;
    }
  }
`

export const FilterOverlay = styled.div`
  z-index: 9999999;
  position: fixed;
  top: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: white;
`

export const OverlayWrap = styled.div`
  max-width: 770px;
  margin: 0 auto;
  padding: 56px 15px;
  position: relative;
  height: 100%;
  @media (max-width: 576px) {
    max-width: 506px;
    padding: 40px 15px;
  }
`

export const OverlayButtonWrap = styled.div`
  position: absolute;
  bottom: 56px;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 16px 16px;
  width: calc(100% - 30px);
  margin-right: -15px;
  div {
    button {
      width: 100%;
    }
  }
  @media (max-width: 576px) {
    bottom: 40px;
    #select {
      grid-area: 1 / 1 / 2 / 3;
    }
    #clear {
      grid-area: 2 / 1 / 2 / 3;
    }
  }
`

export const SidebarHeading = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.1px;
  color: #101828;
  margin-bottom: 24px;
  @media (max-width: 992px) {
    font-size: 22px;
    line-height: 30px;
  }
`

export const SidebarFilterHead = styled.label`
  font-weight: 700;
`

export const SectionWrapper = styled.div`
${(props) => props.overlay === true && css`
    height: calc(100% - 110px);
    overflow-y: scroll;
    @media (max-width: 576px) {
      height: calc(100% - 185px);
    } 
`}
`

export const SidebarSection = styled.div`
  color: #101828;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin-right: 16px;
  }
  label {
    margin-left: 36px;
  }
  .select {
    display: block;
    margin-bottom: 16px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.active {
      color: #002DC2;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: -36px;
    height: 18px;
    width: 18px;
    border: 1px solid #9CAEFF;
    border-radius: 4px;
    background-color: white;
  }
  .select:hover input ~ .checkmark {
    background-color: #F0F0F0;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .select input:checked ~ .checkmark:after {
    display: block;
  }
  .select .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3.5px;
    height: 8px;
    border: solid #9CAEFF;
    border-radius: 1px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export const SidebarSubsection = styled.div`
  margin-left: 32px;
`
