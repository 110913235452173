import React from "react"
import { Layout } from "../../components/Layout"
import { graphql } from "gatsby"
import { IntegrationsCardDeck } from "../../components/Integrations/IntegrationsCardDeck"
import ConversionPanelStandard from "../../components/ConversionPanel/ConversionPanelStandard"
import Seo from "../../components/Seo/Seo"

import { formatIntegration } from "../../utils/formatIntegration"
import IntegrationsHero from "../../components/Integrations/components/IntegrationsHero"

export default function IntegrationsPage({ data }) {
  const { allContentfulComponentTile, contentfulComponentConversionPanel, popular, contentfulComponentHero, contentfulSeo } = data
  const integrationsPosts = allContentfulComponentTile?.edges
  const popularPosts = popular?.edges
  const newHero = contentfulComponentHero
  const seo = contentfulSeo
  
  const popularApps = []
  const integrations = []

  integrationsPosts.forEach(integration => integrations.push(formatIntegration(integration)))
  popularPosts.forEach(pop => popularApps.push(formatIntegration(pop)))

  const sortedIntegrations = integrations.sort((a, b) => b.updated - a.updated)

  return (
    <Layout>
      <Seo
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url}
        imageAlt={seo?.graphImage?.description}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <IntegrationsHero component={newHero}/>
      <IntegrationsCardDeck posts={sortedIntegrations} popular={popularApps}/>
      <ConversionPanelStandard component={contentfulComponentConversionPanel} />
    </Layout>
  )
}

export const integrationsQuery = graphql`
  query integrationsPageQuery {
    contentfulComponentHero( id: { eq: "898a0dd6-cb2b-5ae6-ad7d-9a80091601c6"}) {
      __typename
      id
      type
      background
      alignImage
      subheading {
        raw
      }
      breadcrumbs
      kicker
      heading
      headingSize
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      featuredImage {
        gatsbyImageData
        file {
          url
        }
      }
    }
    allContentfulComponentTile(
      filter: { category: { elemMatch: { title: { eq: "Integrations" } } } }
    ) {
      edges {
        node {
          __typename
          id
          internalName
          category {
            title
          }
          collections {
            title
          }
          departments {
            title
          }
          heading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
          updatedAt(formatString: "YYYYMMDDSS")
        }
      }
    }
    popular: allContentfulComponentTile(
      filter: {category: {elemMatch: {title: {in: "Popular"}}}}
      limit: 8
    ) {
      edges {
        node {
          __typename
          id
          internalName
          category {
            title
            id
          }
          collections {
            id
            title
          }
          departments {
            id
            title
          }
          heading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
    }
    contentfulComponentConversionPanel(
      id: {eq: "b9bc9ad5-5b53-524b-bf40-f3578c5db30d"}
    ) {
      id
      textAlign
      alignContent
      background
      kicker
      noTopPadding
      noBottomPadding
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      subheading {
        raw
      }
      type
    }
    contentfulSeo(id: {eq: "e69fe93e-1ad1-5b8f-b98a-f932c2657a3e"}) {
      graphImage {
        description
        title
        file {
          url
        }
      }
      seoTitle
      seoDescription
      canonical
      noIndex
      metaTags
      article
    }
  }
`
